<i18n src="@/locales/articles-card-content.json" />
<i18n src="@/locales/card-home-content-clinic.json" />
<i18n src="@/locales/clinic.json" />

<template>
  <div class="clinic">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <section class="section">
      <div class="columns is-centered">
        <div
          v-for="cardContent in cardHomeContentClinic"
          :key="cardContent.id"
          class="column is-2"
        >
          <ImanCountryCard
            :content="cardContent"
            :country-card-class="countryOverlayClass"
          />
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <p>
          {{
            $t('article_p1')
          }}
        </p>
      </ImanArticle>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          {{ $t('article_p2') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_2') }}</ImanH3>
        <p>
          {{ $t('article_p3') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_2') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_3') }}
        </h3>
        <p>
          {{ $t('article_p4') }}
        </p>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <p>
            {{ $t('collapse_p1_1') }}
          </p>
          <div v-html="$t('collapse_p1_2')" />
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <p>
            {{ $t('collapse_p2_1') }}
          </p>
          <p>
            {{ $t('collapse_p2_2') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_3')">
            {{ $t('more_info_3') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_3')"
        >
          <p>
            {{ $t('collapse_p3') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_4')"
        >
          <p>
            {{ $t('collapse_p4') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_5')"
        >
          <p>
            {{ $t('collapse_p5') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_5')">
            {{ $t('more_info_5') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_6')"
        >
          <p>
            {{ $t('collapse_p6') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_6')">
            {{ $t('more_info_6') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_7')"
        >
          <p>
            {{ $t('collapse_p7') }}
          </p>
        </imancollapsablecard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import ImanCountryCard from "@/components/ImanCountryCard";
  import {cardHomeContentClinicMixin} from "@/mixins/card-home-content-clinic-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'Clinic',
    components: {
      ImanArticleSection,
      ImanCountryCard

    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin,
      cardHomeContentClinicMixin
    ],
    data() {
      return {
        articlesCardContentId: [53, 68, 83, 84],
        mainBackgroundClass: 'none-background',
        overlayClass: 'overlay-half-circle',
        countryOverlayClass: {
          overlay:'overlay show-overlay',
          element: 'element show-element'
        },
        img: [
          {
            path: 'clinic/laboratoire-protheses-dentaires.jpg',
            position: 'right'
          },
          {
            path: 'clinic/dentistes.jpg',
            position: 'right'
          }
        ]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../assets/img/thumbnail/MINIATURE_4.0.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
          this.countryOverlayClass = {
            overlay:'overlay',
            element: 'element'
          }
        }
        if (document.body.scrollTop > 800 || document.documentElement.scrollTop > 800) {
          this.countryOverlayClass = {
            overlay:'overlay show-overlay',
            element: 'element show-element'
          }
        }

        if (document.body.scrollTop > 1450 || document.documentElement.scrollTop > 1450) {
          this.mainBackgroundClass = 'fixed-background'
        }

        if (document.body.scrollTop > 2632 || document.documentElement.scrollTop > 2632) {
          this.mainBackgroundClass = 'none-background'
        }

        if (document.body.scrollTop > 2800 || document.documentElement.scrollTop > 2800) {
          this.mainBackgroundClass = 'none-background'

        }

        if (document.body.scrollTop > 3640 || document.documentElement.scrollTop > 3640) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../assets/img/clinic/h1-clinic-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../assets/img/clinic/h1-clinic-d.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../assets/img/clinic/h1-mobile-clinic-m.jpg");
    }
  }
</style>
